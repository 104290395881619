<template>
  <div class="row">
    <div
      v-for="(field, index) in config.fields"
      :key="index"
      :class="'col-md-' + field.size"
    >
      <ADVField
        v-if="field.enabled != false"
        :field="field"
        :form="dataForm"
        v-on="$listeners"
      />
    </div>
  </div>
</template>
<script>
import ADVField from "./ADVField";

export default {
  name: "FormFlat",
  props: ["config", "dataForm"],

  components: {
    ADVField,
  },
  // watch: {
  //   config: {
  //     handler(c) {
  //       // console.log("changed field", f.name, f.value);
  //       console.log("changed config", Date.now());
  //       console.log(c);
  //     },
  //     deep: true,
  //   },
  // }
};
</script>
